const slidesContainer = document.getElementById("map-slides-container");
const slide = document.querySelector(".slide-map");
const prevButton = document.getElementById("slide-arrow-prev");
const nextButton = document.getElementById("slide-arrow-next");

let counter = 0;

const slidesAmount = 2;

if (counter == 0) {
    prevButton.style.visibility= 'hidden';
} else if (counter == slidesAmount) {
    nextButton.style.visibility= 'hidden';
}

nextButton.addEventListener("click", () => {
  const slideWidth = slide.clientWidth;
  if (counter == slidesAmount) {
    nextButton.style.visibility= 'hidden';
  } else {
    slidesContainer.scrollLeft += slideWidth;
    prevButton.style.visibility= 'visible';
    counter++;
  }
});

prevButton.addEventListener("click", () => {
  const slideWidth = slide.clientWidth;
  if (counter == 0) {
    slidesContainer.scrollRight += (slideWidth * counter);
    prevButton.style.visibility= 'hidden';
    counter = 0;
  } else {
    slidesContainer.scrollLeft -= slideWidth;
    nextButton.style.visibility= 'visible';
    counter--;
  }
});
